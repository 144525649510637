
import { UFButtonText, UFDialogText } from "@/utils/literals";
import { Component, Prop, Vue } from "vue-property-decorator";
import VueClipboard from 'vue-clipboard2'
import { EventType, FeedbackType } from "@/schemas/Enums";
Vue.use(VueClipboard);

@Component({
  name: "DisplayEndDialog",
})
export default class DisplayEndDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  display!: boolean;
  @Prop({ type: String, required: true })
  sessionId!: boolean;
  @Prop({ type: Boolean, required: true })
  feedbackEnable!: boolean;
  copyDone=false;
  sessionFull='';
  username=''



  copyClicked(){
    this.copyDone=true
  }

  data(): any {
    return {
      sessionFull: '',
      username: '',
      copyDone: false,
    }
  }

  created(): void {

    this.$store.dispatch("session/fetchFullSession", {
      sessionId: this.$route.params.sessionId,
      next: (session) => {
        this.sessionFull=session
      },
    });
    this.$store.dispatch("user/fetchCurrentUsername", {
      next:(user)=> {
        this.username = user.username
      }
    });
  }

  get DisplayUsernameCopy(): boolean {
    if (this.sessionFull.setting) {
      return this.sessionFull.setting.display_username_copy ? this.sessionFull.setting.display_username_copy : false;
    }
    return false;
  }


  redirectToFeedback(): void {
    this.$router.push(`/feedback/${this.sessionId}`);
  }
  redirectToHome(): void {
    this.$router.push({
      name: "home",
    });
  }
  getUFButtonText(key: string): string {
    return UFButtonText[key];
  }
  getUFDialogText(key: string): string {
    return UFDialogText[key];
  }
}
